export const cssBaseLine = {
	/* CSS Custom Properties Definitions */

	"input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
		WebkitAppearance: "none",
		margin: 0,
	},
	'input[type="number"]': {
		MozAppearance: "textfield",
	},

	".bg-primary": { backgroundColor: "#FFF" },
	".bg-secondary": { backgroundColor: "#F6F6F9" },
	".section": {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		gap: "24px",
		padding: "16px 24px 24px 24px",
		borderRadius: "24px",
		backgroundColor: "white",
	},
	".contain": { width: "100%", height: "100%", objectFit: "contain" },

	/* position */
	".top-0": { top: "0" },
	".left-0": { left: "0" },
	".right-0": { right: "0" },
	".bottom-0": { bottom: "0" },
	".top-8": { top: "64px" },
	".top-9": { top: "72px" },
	".ps-f": { position: "fixed" },
	".ps-a": { position: "absolute" },
	".ps-r": { position: "relative" },
	".ps-s": { position: "sticky" },

	/* dimensions */
	".w-100": { width: "100%" },
	".w-100v": { width: "100vw" },
	".w-1": { width: "8px" },
	".w-2": { width: "16px" },
	".w-3": { width: "24px" },
	".w-4": { width: "32px" },
	".w-5": { width: "40px" },
	".w-6": { width: "48px" },
	".w-7": { width: "56px" },
	".w-8": { width: "64px" },
	".w-9": { width: "72px" },
	".w-10": { width: "80px" },
	".w-11": { width: "88px" },
	".w-mc": { width: "max-content" },

	".h-100": { height: "100%" },
	".h-100v": { height: "100vh" },
	".h-1": { height: "8px" },
	".h-2": { height: "16px" },
	".h-3": { height: "24px" },
	".h-4": { height: "32px" },
	".h-5": { height: "40px" },
	".h-6": { height: "48px" },
	".h-7": { height: "56px" },
	".h-8": { height: "64px" },
	".h-9": { height: "72px" },
	".h-10": { height: "80px" },
	".h-11": { height: "88px" },
	".h-mc": { height: "max-content" },

	/* display */
	".d-f": { display: "flex" },
	".d-n": { display: "none" },
	".fd-r": { flexDirection: "row" },

	".fd-c": { flexDirection: "column" },
	".jc-c": { justifyContent: "center" },
	".jc-sb": { justifyContent: "space-between" },
	".jc-fs": { justifyContent: "flex-start" },
	".jc-fe": { justifyContent: "flex-end" },

	".ai-c": { alignItems: "center" },
	".ai-fs": { alignItems: "flex-start" },
	".ai-fe": { alignItems: "flex-end" },
	".ai-bl": { alignItems: "baseline" },

	".fg-1": { flexGrow: "1" },
	".fg-0": { flexGrow: "0" },
	".fs-1": { flexShrink: "1" },
	".fs-0": { flexShrink: "0" },

	".of-a": { overflow: "auto" },
	".of-h": { overflow: "hidden" },

	/* spacing */
	/* gap */
	".gap-0": { gap: "0px" },
	".gap-05": { gap: "4px" },
	".gap-1": { gap: "8px" },
	".gap-12px": { gap: "12px" },
	".gap-2": { gap: "16px" },
	".gap-3": { gap: "24px" },
	".gap-4": { gap: "32px" },
	".gap-5": { gap: "40px" },

	/* padding */
	".p-05": { padding: "4px" },
	".p-1": { padding: "8px" },
	".p-2": { padding: "16px" },
	".p-3": { padding: "24px" },
	".p-4": { padding: "32px" },
	".pt-0": { paddingTop: "0px" },
	".pt-1": { paddingTop: "8px" },
	".pt-2": { paddingTop: "16px" },
	".pt-3": { paddingTop: "24px" },
	".pt-4": { paddingTop: "32px" },
	".pr-1": { paddingRight: "8px" },
	".pr-2": { paddingRight: "16px" },
	".pr-3": { paddingRight: "24px" },
	".pr-4": { paddingRight: "32px" },
	".pb-1": { paddingBottom: "8px" },
	".pb-2": { paddingBottom: "16px" },
	".pb-3": { paddingBottom: "24px" },
	".pb-4": { paddingBottom: "32px" },
	".pl-1": { paddingLeft: "8px" },
	".pl-2": { paddingLeft: "16px" },
	".pl-3": { paddingLeft: "24px" },
	".pl-4": { paddingLeft: "32px" },

	".px-05": { paddingLeft: "4px", paddingRight: "4px" },
	".px-1": { paddingLeft: "8px", paddingRight: "8px" },
	".px-2": { paddingLeft: "16px", paddingRight: "16px" },
	".px-3": { paddingLeft: "24px", paddingRight: "24px" },
	".px-4": { paddingLeft: "32px", paddingRight: "32px" },
	".py-05": { paddingTop: "4px", paddingBottom: "4px" },
	".py-1": { paddingTop: "8px", paddingBottom: "8px" },
	".py-12px": { paddingTop: "12px", paddingBottom: "12px" },
	".py-2": { paddingTop: "16px", paddingBottom: "16px" },
	".py-3": { paddingTop: "24px", paddingBottom: "24px" },
	".py-4": { paddingTop: "32px", paddingBottom: "32px" },
	".py-5": { paddingTop: "40px", paddingBottom: "40px" },

	/* margin */
	".m-1": { margin: "8px" },
	".m-2": { margin: "16px" },
	".m-3": { margin: "24px" },
	".m-4": { margin: "32px" },
	".mt-1": { marginTop: "8px" },
	".mt-2": { marginTop: "16px" },
	".mt-3": { marginTop: "24px" },
	".mt-4": { marginTop: "32px" },
	".mr-1": { marginRight: "8px" },
	".mr-2": { marginRight: "16px" },
	".mr-3": { marginRight: "24px" },
	".mr-4": { marginRight: "32px" },
	".mb-1": { marginBottom: "8px" },
	".mb-2": { marginBottom: "16px" },
	".mb-3": { marginBottom: "24px" },
	".mb-4": { marginBottom: "32px" },
	".ml-1": { marginLeft: "8px" },
	".ml-2": { marginLeft: "16px" },
	".ml-3": { marginLeft: "24px" },
	".ml-4": { marginLeft: "32px" },

	/* border */
	".br-0": { borderRadius: "0px" },
	".br-1": { borderRadius: "8px" },
	".br-12px": { borderRadius: "12px" },
	".br-2": { borderRadius: "16px" },
	".br-3": { borderRadius: "24px" },
	".br-4": { borderRadius: "32px" },

	/* transition */
	".tr-a-2": { transition: "all 0.2s ease-in-out" },

	".hov-item": {
		backgroundColor: "rgba(221, 235, 235, 0.29);",
		"&:hover": { backgroundColor: "white", boxShadow: "0px 6px 21px - 7px rgba(0, 0, 0, 0.75)" },
	},
};
